.container{
    display: flex;
    gap: 62px;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.item{
    font-size: 20px;
    text-decoration: none;
    color: var(--primary-font-color);
    transition: border-bottom 0.1s ease-in-out;
    padding: 7px 0;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
}

.item:hover{
    border-bottom: 2px solid var(--primary-font-color);
    box-sizing: border-box;
}