.container{
    display: flex;
    flex-direction: column;
    font-size: 87px;
}

.first{
    color: var(--secondary-font-color);
    margin-bottom: 18px;
}

.second{
    margin-left: 138px;
}


