.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 100px);
    justify-content: space-between;
}

.logo{
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
}

.about{
    display: flex;
    gap: 193px;
    font-size: 33px;
    margin-bottom: 130px;
}



